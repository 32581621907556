import { Divider, Grid, InputLabel, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/it';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import {
  getAllBenefitsCallback,
  getAllCategoriesCallback,
  getAllEnteCallback,
  getAllTagsCallback,
  postMisuraCallback,
  putMisuraCallback,
  provincesState,
  regionsState,
  agenciesState,
  tagsState,
  benefitsState,
  categoriesState,
  acceptedImageType,
  getBase64,
  removeNullishOrEmptyFields,
  measureState,
  getMisuraByIdCallback,
  getAllMisureCallback,
  MisuraClass,
  Misura,
  agenciesLoaderState,
  benefitsLoaderState,
  categoriesLoaderState,
  tagsLoaderState,
  measureLoaderState,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { LBTButton, LBTSelect, LBTTextField } from '@laborability/components';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ size: [] }],
    [{ font: [] }],
    [{ align: ['right', 'center', 'justify'] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    [{ color: ['red', '#785412'] }],
    [{ background: ['red', '#785412'] }],
    [{ indent: '-1' }, { indent: '+1' }],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'link',
  'color',
  'image',
  'background',
  'align',
  'size',
  'font',
];

const StyledReactQuill = styled(ReactQuill)`
  min-height: 400px;
  .ql-container {
    height: 300px;
  }
`;

interface Props {
  id?: number;
}

export function MeasureForm({ id }: Props) {
  const regions = useRecoilValue(regionsState);
  const provinces = useRecoilValue(provincesState);
  const enti = useRecoilValue(agenciesState);
  const tags = useRecoilValue(tagsState);
  const benefits = useRecoilValue(benefitsState);
  const categories = useRecoilValue(categoriesState);

  const postMisura = useRecoilCallback(postMisuraCallback, []);
  const putMisura = useRecoilCallback(putMisuraCallback, []);
  const getMisura = useRecoilCallback(getMisuraByIdCallback, []);
  const getAllMisura = useRecoilCallback(getAllMisureCallback, []);
  const getAllEntes = useRecoilCallback(getAllEnteCallback, []);
  const getAllTags = useRecoilCallback(getAllTagsCallback, []);
  const getAllBenefits = useRecoilCallback(getAllBenefitsCallback, []);
  const getAllCategories = useRecoilCallback(getAllCategoriesCallback, []);
  const [current, setCurrent] = useRecoilState(measureState);

  const entiLoader = useRecoilValue(agenciesLoaderState);
  const tagsLoader = useRecoilValue(tagsLoaderState);
  const benefitsLoader = useRecoilValue(benefitsLoaderState);
  const categoriesLoader = useRecoilValue(categoriesLoaderState);
  const measureLoader = useRecoilValue(measureLoaderState);
  const isLoading =
    entiLoader ||
    tagsLoader ||
    benefitsLoader ||
    categoriesLoader ||
    measureLoader;

  const resetFormState = () => {
    setCurrent(new MisuraClass());
  };

  useEffect(() => {
    getAllEntes();
    getAllTags();
    getAllBenefits();
    getAllCategories();
  }, []);

  useEffect(() => {
    if (id) getMisura({ id });
    else resetFormState();
  }, [id]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it-IT">
      <ModalForm
        defaultValues={new MisuraClass()}
        isLoading={isLoading}
        currentValues={
          {
            ...current,
            main_category: current?.categories_ids?.find(id =>
              categories.find(
                category => !category.parent_id && category.id === id,
              ),
            ),
            sub_category: current?.categories_ids?.find(id =>
              categories.find(
                category => category.parent_id && category.id === id,
              ),
            ),
            start_date: current?.start_date
              ? dayjs(current.start_date, 'DD-MM-YYYY HH:mm:ss')
              : dayjs(),
            end_date: current?.end_date
              ? dayjs(current.end_date, 'DD-MM-YYYY HH:mm:ss')
              : dayjs(),
          } as Misura
        }
        validation={values => {
          return {
            ...(values.name === '' ? { name: 'Il nome è obbligatorio' } : {}),
            ...(values.description === ''
              ? { description: 'La descrizione è obbligatoria' }
              : {}),
            ...(!values.agency_id ? { agency_id: 'Inserire un ente' } : {}),
            ...(!values.benefit_type_id
              ? { benefit_type_id: 'Inserire un tipo di beneficio' }
              : {}),
            ...(!values.main_category
              ? { main_category: 'La categoria principale è obbligatoria' }
              : {}),
            ...(values.regional && !values?.regions_ids?.length
              ? { regions_ids: 'Selezionare almeno una regione' }
              : {}),
          };
        }}
        handleSubmit={async (values: any) => {
          const serverObj = {
            ...values,
            categories_ids: [
              values.main_category,
              ...(values.sub_category ? [values.sub_category] : []),
            ],
            start_date: values.start_date.format('DD-MM-YYYY HH:mm:ss'),
            end_date: values.end_date.format('DD-MM-YYYY HH:mm:ss'),
          };

          serverObj.how_it_works =
            values &&
            values.how_it_works &&
            values.how_it_works.replaceAll('<p><br></p>', '');
          serverObj.target_audience =
            values &&
            values.target_audience &&
            values.target_audience.replaceAll('<p><br></p>', '');
          serverObj.access_requirements =
            values &&
            values.access_requirements &&
            values.access_requirements.replaceAll('<p><br></p>', '');
          serverObj.bonus_value =
            values &&
            values.bonus_value &&
            values.bonus_value.replaceAll('<p><br></p>', '');
          serverObj.application_period =
            values &&
            values.application_period &&
            values.application_period.replaceAll('<p><br></p>', '');
          serverObj.application_process =
            values &&
            values.application_process &&
            values.application_process.replaceAll('<p><br></p>', '');
          delete serverObj.main_category;
          delete serverObj.sub_category;

          if (values.id)
            return await putMisura({
              id: values.id,
              ...removeNullishOrEmptyFields(serverObj),
            });
          await postMisura(removeNullishOrEmptyFields(serverObj));
        }}
        resetGridState={() => getAllMisura({ limit: 100000 })}
        resetFormState={resetFormState}
        customButtons={[
          params => (
            <LBTButton
              sx={{ marginLeft: '16px' }}
              type="submit"
              variant="contained"
              onClick={() => {
                params.setFieldValue('draft', true);
                params.handleSubmit();
              }}
              disabled={params.isSubmitting}
            >
              {!current.id || params.values.draft
                ? 'Salva come bozza'
                : 'Converti in bozza'}
            </LBTButton>
          ),
          params => (
            <LBTButton
              sx={{ marginLeft: '16px' }}
              type="submit"
              variant="contained"
              onClick={() => {
                params.setFieldValue('draft', false);
                params.handleSubmit();
              }}
              disabled={params.isSubmitting}
            >
              {current.id
                ? params.values.draft
                  ? 'Salva'
                  : 'Modifica'
                : 'Registra'}
            </LBTButton>
          ),
        ]}
      >
        {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
          <>
            <Grid item mobile={12}>
              <LBTTextField
                label="Nome misura"
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                required
                error={!!errors?.name}
                helperText={errors?.name}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTTextField
                label="Descrizione"
                type="text"
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                required
                error={!!errors?.description}
                helperText={errors?.description}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTTextField
                label="Video URL"
                type="text"
                name="video_path"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.video_path}
                error={!!errors?.video_path}
                helperText={errors?.video_path}
              />
            </Grid>
            <Grid item mobile={12}>
              <Stack flexDirection={'row'} display={'flex'}>
                <Typography
                  display={'flex'}
                  flex={0.5}
                  alignItems={'center'}
                  justifyContent={'left'}
                >
                  Immagine
                </Typography>
                <LBTTextField
                  sx={{ flex: 1 }}
                  type="file"
                  inputProps={acceptedImageType}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const file =
                      event &&
                      event.target &&
                      event.target.files &&
                      event.target.files[0];

                    if (file) {
                      getBase64(file as File).then(data =>
                        setFieldValue('image_path', data),
                      );
                    }
                  }}
                  error={!!errors?.image_path}
                  helperText={errors?.image_path}
                />
              </Stack>
              <img src={values.image_path} style={{ maxHeight: '100px' }}></img>
            </Grid>
            <Grid item mobile={12}>
              <LBTTextField
                label="Link WEB"
                type="text"
                name="web_link"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.web_link}
                error={!!errors?.web_link}
                helperText={errors?.web_link}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="agency_id-select"
                name="agency_id"
                value={values.agency_id}
                label="Ente erogatore"
                items={enti.map(item => ({
                  id: item.id as number,
                  name: item.name as string,
                }))}
                handleChange={e => setFieldValue('agency_id', e)}
                onBlur={handleBlur}
                required
                error={!!errors?.agency_id}
                helperText={errors?.agency_id}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="main_category-select"
                name="main_category"
                value={values.main_category}
                label="Categoria"
                handleChange={e => {
                  setFieldValue('main_category', e);
                  setFieldValue('sub_category', undefined);
                }}
                onBlur={handleBlur}
                fullWidth
                required
                items={categories
                  .filter(item => !item.parent_id)
                  .map(item => ({
                    id: item.id as number,
                    name: item.name as string,
                  }))}
                error={!!errors?.main_category}
                helperText={errors?.main_category}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="sub_category-select"
                name="sub_category"
                value={values.sub_category}
                label="Sotto categoria"
                handleChange={e => setFieldValue('sub_category', e)}
                onBlur={handleBlur}
                disabled={!values.main_category}
                items={categories
                  .filter(item => item.parent_id === values.main_category)
                  .map(item => ({
                    id: item.id as number,
                    name: item.name as string,
                  }))}
                error={!!errors?.sub_category}
                helperText={errors?.sub_category}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                multiple
                id="tag_ids-select"
                name="tag_ids"
                value={values.tag_ids}
                label="Tags"
                handleChange={e => setFieldValue('tag_ids', e)}
                onBlur={handleBlur}
                required
                items={tags.map(item => ({
                  id: item.id as number,
                  name: item.name as string,
                }))}
                error={!!errors?.tag_ids}
                helperText={errors?.tag_ids}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="benefit_type_id-select"
                name="benefit_type_id"
                value={values.benefit_type_id}
                label="Tipo beneficio"
                required
                handleChange={e => setFieldValue('benefit_type_id', e)}
                onBlur={handleBlur}
                items={benefits.map(item => ({
                  id: item.id as number,
                  name: item.name as string,
                }))}
                error={!!errors?.benefit_type_id}
                helperText={errors?.benefit_type_id}
              />
            </Grid>
            <Grid item mobile={6}>
              <DatePicker
                label="Data inizio"
                name="start_date"
                format="DD/MM/YYYY"
                onChange={e => {
                  setFieldValue('start_date', e);
                  if (e?.isAfter(values.end_date)) setFieldValue('end_date', e);
                }}
                sx={{ width: '100%' }}
                value={values.start_date as Dayjs}
              />
            </Grid>
            <Grid item mobile={6}>
              <DatePicker
                label="Data fine"
                name="end_date"
                format="DD/MM/YYYY"
                minDate={values.start_date as Dayjs}
                onChange={e => {
                  setFieldValue('end_date', e);
                }}
                sx={{ width: '100%' }}
                value={values.end_date as Dayjs}
              />
            </Grid>

            <Grid item mobile={4}>
              <LBTSelect
                id="for_all_incomes-select"
                name="for_all_incomes"
                value={Number(values.for_all_incomes)}
                label="Per tutti i redditi"
                sx={{ minWidth: 0 }}
                handleChange={e => {
                  setFieldValue('for_all_incomes', !!e);
                }}
                onBlur={handleBlur}
                required
                items={[
                  { id: 1, name: 'Sì' },
                  { id: 0, name: 'No' },
                ]}
                error={!!errors?.for_all_incomes}
                helperText={errors?.for_all_incomes}
              />
            </Grid>
            <Grid item mobile={4}>
              <LBTSelect
                id="question_online_or_phone-select"
                name="question_online_or_phone"
                value={Number(values.question_online_or_phone)}
                label="Domanda online o al telefono"
                sx={{ minWidth: 0 }}
                handleChange={e => {
                  setFieldValue('question_online_or_phone', !!e);
                }}
                onBlur={handleBlur}
                required
                items={[
                  { id: 1, name: 'Sì' },
                  { id: 0, name: 'No' },
                ]}
                error={!!errors?.question_online_or_phone}
                helperText={errors?.question_online_or_phone}
              />
            </Grid>
            <Grid item mobile={4}>
              <LBTSelect
                id="news-select"
                name="news"
                value={Number(values.news)}
                label="Novità"
                sx={{ minWidth: 0 }}
                handleChange={e => {
                  setFieldValue('news', !!e);
                }}
                onBlur={handleBlur}
                required
                items={[
                  { id: 1, name: 'Sì' },
                  { id: 0, name: 'No' },
                ]}
                error={!!errors?.news}
                helperText={errors?.news}
              />
            </Grid>
            <Grid item mobile={3}>
              <LBTSelect
                id="national-select"
                name="national"
                value={Number(values.national)}
                label="Tipologia bonus"
                sx={{ minWidth: 0 }}
                handleChange={e => {
                  if (!!e) {
                    setFieldValue('regions_ids', []);
                    setFieldValue('provinces_ids', []);
                    setFieldValue('national', true);
                    setFieldValue('regional', false);
                  } else {
                    setFieldValue('national', false);
                    setFieldValue('regional', true);
                  }
                }}
                onBlur={handleBlur}
                items={[
                  { id: 1, name: 'Nazionale' },
                  { id: 0, name: 'Regionale' },
                ]}
                error={!!errors?.national}
                helperText={errors?.national}
              />
            </Grid>
            <Grid item mobile={9}>
              <LBTSelect
                id="regions_ids-select"
                name="regions_ids"
                value={values.regions_ids}
                label="Regioni"
                multiple
                disabled={values.national}
                required={values.regional}
                handleChange={e => {
                  const newId = Number(e);
                  // rimuove le provincie selezionate una volta aggiunta la seconda regione
                  if (
                    values.regions_ids &&
                    values.regions_ids.length >= 1 &&
                    !values.regions_ids.find((id: number) => id === newId)
                  )
                    setFieldValue('provinces_ids', []);
                  setFieldValue('regions_ids', e);
                }}
                onBlur={handleBlur}
                items={regions.map(item => ({
                  id: item.id as number,
                  name: item.name as string,
                }))}
                error={!!errors?.regions_ids?.length}
                helperText={errors?.regions_ids}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="provinces_ids-select"
                name="provinces_ids"
                value={values.provinces_ids}
                label="Province"
                multiple
                disabled={
                  values.national ||
                  !values.regions_ids ||
                  values.regions_ids.length === 0 ||
                  values.regions_ids.length >= 2
                }
                handleChange={e => setFieldValue('provinces_ids', e)}
                onBlur={handleBlur}
                items={provinces
                  .filter(item =>
                    values.regions_ids?.find(
                      (regionId: number) => regionId === item.region_id,
                    ),
                  )
                  .map(item => ({
                    id: item.id as number,
                    name: item.name as string,
                  }))}
                error={!!errors?.provinces_ids?.length}
                helperText={errors?.provinces_ids}
              />
            </Grid>

            <Grid item mobile={6}>
              <LBTTextField
                label="Range iniziale"
                type="text"
                name="initial_range"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.initial_range}
                error={!!errors?.initial_range}
                helperText={errors?.initial_range}
              />
            </Grid>
            <Grid item mobile={6}>
              <LBTTextField
                label="Range finale"
                type="text"
                name="final_range"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.final_range}
                error={!!errors?.final_range}
                helperText={errors?.final_range}
              />
            </Grid>

            <Divider></Divider>

            <Grid item mobile={12}>
              <InputLabel>
                <Typography component={'h1'}>Che cosa fare?</Typography>
              </InputLabel>
              <StyledReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={values.how_it_works}
                onChange={e => {
                  setFieldValue('how_it_works', e);
                }}
              />
            </Grid>
            <Grid item mobile={12}>
              <InputLabel>
                <Typography component={'h1'}>A chi è rivolto</Typography>
              </InputLabel>
              <StyledReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={values.target_audience}
                onChange={e => {
                  setFieldValue('target_audience', e);
                }}
              />
            </Grid>
            <Grid item mobile={12}>
              <InputLabel>
                <Typography component={'h1'}>Requisiti per accedere</Typography>
              </InputLabel>
              <StyledReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={values.access_requirements}
                onChange={e => {
                  setFieldValue('access_requirements', e);
                }}
              />
            </Grid>
            <Grid item mobile={12}>
              <InputLabel>
                <Typography component={'h1'}>Valore del bonus</Typography>
              </InputLabel>
              <StyledReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={values.bonus_value}
                onChange={e => {
                  setFieldValue('bonus_value', e);
                }}
              />
            </Grid>
            <Grid item mobile={12}>
              <InputLabel>
                <Typography component={'h1'}>Quando fare domanda</Typography>
              </InputLabel>
              <StyledReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={values.application_period}
                onChange={e => {
                  setFieldValue('application_period', e);
                }}
              />
            </Grid>
            <Grid item mobile={12}>
              <InputLabel>
                <Typography component={'h1'}>Come fare domanda</Typography>
              </InputLabel>
              <StyledReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={values.application_process}
                onChange={e => {
                  setFieldValue('application_process', e);
                }}
              />
            </Grid>
          </>
        )}
      </ModalForm>
    </LocalizationProvider>
  );
}
