import Divider, { DividerProps } from '@mui/material/Divider';
import * as React from 'react';
import styled from 'styled-components';
import { LBTDividerSize } from '../../enums';

export type LBTDividerProps = DividerProps & {
  size?: LBTDividerSize;
  color?: string;
  width: string;
  datatestid?: string;
};

const StyledDivider = styled(Divider)<
  DividerProps & {
    size: LBTDividerSize;
    color?: string;
    datatestid?: string;
    width: string;
  }
>(({ theme, size, color, datatestid, width, ...props }) => {
  return {
    datatestid,
    '&.MuiDivider-root': {
      borderRightColor: color,
      borderBottomColor: color,
      borderTopWidth: `0`,
      borderLeftWidth: `0`,
      borderBottomWidth: props.orientation === 'horizontal' ? size : 0,
      borderRightWidth: props.orientation === 'vertical' ? size : 0,
      width: props.orientation === 'vertical' ? 0 : width,
      height: props.orientation === 'horizontal' ? 0 : width,
    },
  };
});

const LBTDivider: React.FC<LBTDividerProps> = ({
  size = LBTDividerSize.SMALL,
  color,
  width = '100%',
  datatestid = '',
  ...props
}: LBTDividerProps) => {
  return (
    <StyledDivider
      size={size}
      color={color}
      width={width}
      datatestid={`lbt-divider-${datatestid}`}
      {...props}
    />
  );
};

export default LBTDivider;
