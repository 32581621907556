import { ColDef } from 'ag-grid-community';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { useGrid } from '../hooks/useGrid';
import {
  ActionRule,
  actionRulesState,
  deleteActionRuleCallback,
  getAllActionRuleCallback,
  getPageActionByIdCallback,
  pageActionState,
  questionPagesState,
} from '@laborability/commons';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionRuleForm } from '../components/Forms';
import { LBTButton } from '@laborability/components';
import { useEffect } from 'react';
import { APP_ROUTES } from '../Routes';
import { appBarState } from '../recoil/states/appBar';

export function ActionConditionPage() {
  const navigate = useNavigate();
  const { id: action_id } = useParams();
  const getAllRules = useRecoilCallback(getAllActionRuleCallback, []);
  const deleteRule = useRecoilCallback(deleteActionRuleCallback, []);
  const getPageAction = useRecoilCallback(getPageActionByIdCallback, []);
  const questionPages = useRecoilValue(questionPagesState);
  const pageAction = useRecoilValue(pageActionState);
  const setAppBarName = useSetRecoilState(appBarState);

  const { rowData, handleFetchAll, handleDelete } = useGrid<ActionRule>({
    state: actionRulesState,
    onFetch: async () => {
      if (action_id) return await getAllRules({ id: action_id });
    },
    onDelete: async ({ id }) => {
      if (action_id) return await deleteRule({ action_id, id });
    },
  });

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Nome',
    },
    {
      field: 'execution_order',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Ordine di esecuzione',
    },
    {
      field: 'target_page_id',
      flex: 3,
      filter: 'agTextColumnFilter',
      headerName: 'Pagina seguente',
      valueFormatter: params =>
        questionPages.find(page => page.id === params.value)?.name ?? '-',
    },
  ];

  useEffect(() => {
    if (!questionPages.length) navigate(APP_ROUTES.QUESTIONARY);
  }, [questionPages]);

  useEffect(() => {
    if (action_id) getPageAction({ id: action_id });
  }, []);

  useEffect(() => {
    setAppBarName(
      `Registra regole azioni${pageAction?.name ? ` - ${pageAction.name}` : ''}`,
    );
  }, [pageAction]);

  return (
    <GridLayout<ActionRule>
      pageName="Regole"
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={ActionRuleForm}
      recordNameAccessor={item => String(item.execution_order) ?? ''}
      gridFilters={
        <LBTButton
          variant="outlined"
          color="primary"
          onClick={() => navigate(-1)}
        >
          Indietro
        </LBTButton>
      }
    />
  );
}
