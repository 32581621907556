import { authPost, PATH, authGetAll, authDelete, authPut } from '../../http';
import { Id, Post, Put, QuestionFlow } from '../types';

export const postQuestionFlow = async (item: Post<QuestionFlow>) => {
  return await authPost(`api/v1/${PATH.QUESTIONARIES}/`, item);
};

export const getQuestionFlowById = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.QUESTIONARIES}/${id}`);
};

export const getAllQuestionFlow = async () => {
  return await authGetAll(`api/v1/${PATH.QUESTIONARIES}/`);
};

export const getQuestionFlowStep = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.QUESTIONARIES}/${id}/${PATH.STEPS}`);
};

export const putQuestionFlow = async (item: Put<QuestionFlow>) => {
  return await authPut(`api/v1/${PATH.QUESTIONARIES}`, item);
};

export const deleteQuestionFlow = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.QUESTIONARIES}/${id}`);
};
