import { CheckboxStyle, CheckboxType } from '../../enums';
import { COLORS } from '../../utils';

interface Props {
  style: CheckboxStyle;
  type: CheckboxType;
  disabled?: boolean;
}

export default function IconCheckbox({ style, type, disabled }: Props) {
  const primaryColor =
    style === CheckboxStyle.NEGATIVE
      ? COLORS.getInstance().BW_GREYS_JET_BLACK
      : COLORS.getInstance().PRIMARY_MAIN;
  const secondaryColor =
    style === CheckboxStyle.NEGATIVE
      ? COLORS.getInstance().WHITE
      : COLORS.getInstance().PRIMARY_CONTRAST;

  const getInnerElement = () => {
    if (type === CheckboxType.INTERMEDIATE)
      return (
        <path
          d="M6 11H16"
          stroke={disabled ? '#9B9B9B' : secondaryColor}
          stroke-width="2"
          stroke-linecap="round"
        />
      );
    if (type === CheckboxType.CHECKED)
      return (
        <path
          d="M7 12L9.9088 15C11.2784 11.0672 12.4176 9.3424 15 7"
          stroke={disabled ? '#9B9B9B' : secondaryColor}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      );
    return null;
  };

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.336 16.2288C1.46306 17.3544 1.9677 18.4037 2.76762 19.2056C3.56755 20.0076 4.61557 20.5149 5.7408 20.6448C7.4528 20.8368 9.208 21 11 21C12.792 21 14.5488 20.8368 16.2592 20.6448C17.3844 20.5149 18.4325 20.0076 19.2324 19.2056C20.0323 18.4037 20.5369 17.3544 20.664 16.2288C20.8464 14.528 21 12.7808 21 11C21 9.2192 20.8464 7.472 20.664 5.7696C20.5369 4.64404 20.0323 3.59474 19.2324 2.79278C18.4325 1.99082 17.3844 1.48352 16.2592 1.3536C14.5472 1.1648 12.792 1 11 1C9.208 1 7.4512 1.1632 5.7408 1.3552C4.61557 1.48512 3.56755 1.99242 2.76762 2.79438C1.9677 3.59634 1.46306 4.64564 1.336 5.7712C1.1536 7.4704 1 9.2176 1 11C1 12.7824 1.1536 14.528 1.336 16.2288Z"
        fill={
          disabled
            ? '#F3F3F3'
            : type === CheckboxType.UNCHECKED
              ? COLORS.getInstance().WHITE
              : primaryColor
        }
        stroke={disabled ? '#E8E8E8' : primaryColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      {getInnerElement()}
    </svg>
  );
}
