import {
  ActionRule,
  ActionRuleClass,
  QuestionRule,
  QuestionRuleClass,
} from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const actionRuleState = atom<ActionRule>({
  key: getUUID(),
  default: new ActionRuleClass(),
});

export const actionRulesState = atom<ActionRule[]>({
  key: getUUID(),
  default: [],
});

export const actionRuleLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const actionRulesLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const questionRuleState = atom<QuestionRule>({
  key: getUUID(),
  default: new QuestionRuleClass(),
});

export const questionRulesState = atom<QuestionRule[]>({
  key: getUUID(),
  default: [],
});

export const questionRuleLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const questionRulesLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
