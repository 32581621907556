import { PageAction } from './pageAction';
import { Question } from './question';

export interface QuestionPage {
  id?: number;
  flow_id: number;
  name?: string;
  hint?: string;
  description?: string;
  questions?: Question[];
  actions?: PageAction[];
  media_title?: string;
  media_subtitle?: string;
  media_description?: string;
  media_link?: string;
  assocaaf?: boolean;
}
export interface PostQuestionPage
  extends Omit<QuestionPage, 'questions' | 'actions'> {
  flow_id: number;
}

export class QuestionPageClass implements QuestionPage {
  id = 0;
  flow_id = 0;
  name = '';
  hint = '';
  description = '';
  questions = [];
  actions = [];
  media_title = '';
  media_subtitle = '';
  media_description = '';
  media_link = '';
  assocaaf = false;
}
