import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { QuestionaryPageForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import {
  QuestionPage,
  deleteQuestionPageCallback,
  getAllQuestionPageCallback,
  getQuestionFlowByIdCallback,
  questionFlowState,
  questionPagesState,
} from '@laborability/commons';
import { useNavigate, useParams } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';
import { Box } from '@mui/material';
import { APP_ROUTES } from '../Routes';
import { LBTButton } from '@laborability/components';
import { useEffect } from 'react';
import { appBarState } from '../recoil/states/appBar';

export function QuestionFlowPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const getAllPage = useRecoilCallback(getAllQuestionPageCallback, []);
  const deletePage = useRecoilCallback(deleteQuestionPageCallback, []);
  const getFlow = useRecoilCallback(getQuestionFlowByIdCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);
  const questionFlow = useRecoilValue(questionFlowState);

  const { rowData, handleFetchAll, handleDelete } = useGrid<QuestionPage>({
    state: questionPagesState,
    onFetch: async () => {
      if (id) return await getAllPage({ id });
    },
    onDelete: deletePage,
  });

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Nome',
    },
    {
      field: 'description',
      flex: 3,
      filter: 'agTextColumnFilter',
      headerName: 'Descrizione',
    },
    {
      field: 'open',
      headerName: 'Apri',
      width: 100,
      cellRenderer: (data: ICellRendererParams) => (
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(`/${APP_ROUTES.QUESTION_PAGE}/${data.data.id}`)
          }
        >
          <OpenInNew />
        </Box>
      ),
      pinned: 'right',
    },
  ];

  useEffect(() => {
    if (id) getFlow({ id: id });
  }, [id]);

  useEffect(() => {
    setAppBarName(
      `Registra pagine${questionFlow?.name ? ` - ${questionFlow.name}` : ''}`,
    );
  }, [questionFlow]);

  return (
    <GridLayout<QuestionPage>
      pageName={'Pagina'}
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={QuestionaryPageForm}
      recordNameAccessor={item => item.name ?? ''}
      gridFilters={
        <LBTButton
          variant="outlined"
          color="primary"
          onClick={() => navigate(-1)}
        >
          Indietro
        </LBTButton>
      }
    />
  );
}
