import { QuestionPage } from './questionPage';

export interface QuestionFlow {
  id?: number;
  name?: string;
  step?: string;
  pages?: QuestionPage[];
}

export class QuestionFlowClass implements QuestionFlow {
  id = 0;
  name = '';
  step = '';
  pages = [];
}

export const QUESTIONARY_STEPS = 9;
