import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import LBTRadio from '../Radio';
import LBTLabel from '../Label';
import { useBreakpoint } from '@laborability/commons';
import { COLORS } from '../../utils';
import LBTCheckbox from '../Checkbox';
import { CheckboxStyle } from '../../enums';

export type LBTChoiceBoxProps = ButtonProps & {
  icon?: React.ReactNode;
  input: string;
  label: string;
  onClick?: () => void;
  datatestid?: string;
};

const StyledBox = styled(Button)<
  ButtonProps & {
    width: string;
    backgroundColor: string;
    datatestid?: string;
    'data-track': string;
  }
>(({ datatestid, backgroundColor, width, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
  variant: 'text',
  '&.MuiButton-root': {
    display: 'flex',
    justifyContent: 'space-between',
    height: '52px',
    width: width,
    backgroundColor: backgroundColor,
    border: `1px solid ${COLORS.getInstance().BLACK}4D`,
    borderRadius: '12px',
    '& .MuiRadio-root': {
      color: COLORS.getInstance().NEUTRAL,
    },
    '&.Mui-checked': {
      '&.MuiRadio-root': {
        color: COLORS.getInstance().PRIMARY_DARK,
      },
    },

    '&:hover': {
      backgroundColor: COLORS.getInstance().PRIMARY_IPERLIGHT,
      '& .MuiRadio-root': {
        color: COLORS.getInstance().PRIMARY_DARK,
      },
    },
    '&:active': {
      border: `2px solid ${COLORS.getInstance().PRIMARY_DARK}`,
      backgroundColor: `${COLORS.getInstance().PRIMARY_MAIN}4D`,
    },
  },
}));

const LBTChoiceBox: React.FC<LBTChoiceBoxProps> = ({
  datatestid = '',
  input,
  label,
  ...props
}: LBTChoiceBoxProps) => {
  const [isSelected, setIsSelected] = React.useState(false);
  const { isDesktop } = useBreakpoint();

  const getInput = (input: string, isSelected: boolean) => {
    if (input === 'radio') return <LBTRadio checked={isSelected} />;
    else
      return (
        <LBTCheckbox
          checked={isSelected}
          variant={isSelected ? CheckboxStyle.PRIMARY : CheckboxStyle.NEGATIVE}
        />
      );
  };

  return (
    <StyledBox
      width={!isDesktop ? '100%' : '358px'}
      datatestid={`lbt-select-${datatestid}`}
      backgroundColor={
        !isSelected
          ? COLORS.getInstance().WHITE
          : COLORS.getInstance().PRIMARY_IPERLIGHT
      }
      data-track={datatestid}
      onClick={() => setIsSelected(!isSelected)}
      {...props}
    >
      <LBTLabel variant={'inputFormLabel'}>{label}</LBTLabel>
      {getInput(input, isSelected)}
    </StyledBox>
  );
};

export default LBTChoiceBox;
