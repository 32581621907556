import chroma from 'chroma-js';
import { AppTheme, COLORS } from '@laborability/components/src/utils';
import { CompanyConfig } from '../recoil/types';
import { useRecoilCallback } from 'recoil';
import { getAllCompanyConfigurationByRealmNameCallback } from '../recoil';

interface Returns {
  setCompanyConfig: (name: string) => Promise<any>;
}

export default function useCompanyConfig(): Returns {
  const fetchCompanyConfig = useRecoilCallback(
    getAllCompanyConfigurationByRealmNameCallback,
    [],
  );

  const tinta = (color: string, y: number) => {
    const [r, g, b] = chroma(color).rgb();
    return chroma([
      Math.round(r + (255 - r) * y),
      Math.round(g + (255 - g) * y),
      Math.round(b + (255 - b) * y),
    ]).hex();
  };
  const ombra = (color: string, y: number) => {
    const [r, g, b] = chroma(color).rgb();
    return chroma([
      Math.round(r * y),
      Math.round(g * y),
      Math.round(b * y),
    ]).hex();
  };

  const setColors = (primary?: string) => {
    const primaryPalette =
      primary && primary !== COLORS.getInstance().PRIMARY_MAIN
        ? {
            main: primary,
            iperlight: tinta(primary, 0.9),
            superlight: tinta(primary, 0.8),
            light: tinta(primary, 0.7),
            medium: ombra(primary, 0.9),
            mediumdark: ombra(primary, 0.8),
            dark: ombra(primary, 0.7),
            superdark: ombra(primary, 0.4),
            /* contrastText:
            chroma(primary).luminance() > 0.5 ? '#282828' : '#FFFFFF', */
            contrastText: '#282828',
          }
        : undefined;

    const colors = COLORS.getInstance();
    if (primaryPalette) {
      AppTheme.palette.primary = primaryPalette as any;
      colors.PRIMARY_MAIN = primaryPalette.main;
      colors.PRIMARY_IPERLIGHT = primaryPalette.iperlight;
      colors.PRIMARY_SUPERLIGHT = primaryPalette.superlight;
      colors.PRIMARY_LIGHT = primaryPalette.light;
      colors.PRIMARY_MEDIUM = primaryPalette.medium;
      colors.PRIMARY_MEDIUMDARK = primaryPalette.mediumdark;
      colors.PRIMARY_DARK = primaryPalette.dark;
      colors.PRIMARY_SUPERDARK = primaryPalette.superdark;
      colors.PRIMARY_CONTRAST = primaryPalette.contrastText;
    }

    return { primary: primaryPalette };
  };

  const setCompanyConfig = async (name: string) => {
    console.log(`fetch ${name} config`);
    const res = await fetchCompanyConfig({ name });
    if (!res || !res.data)
      return {
        id: undefined,
        logo: undefined,
        assocaf: undefined,
        fifth_assignment: undefined,
        tfr: undefined,
      }; // TODO controllo risposta
    const primary_color = res.data.find(
      (d: CompanyConfig) => d.key === 'primary_color',
    )?.value;
    const id = res.data.find(
      (d: CompanyConfig) => d.key === 'primary_color',
    )?.company_id;
    const logo = res.data.find((d: CompanyConfig) => d.key === 'logo')?.value;
    const assocaf =
      res.data.find((d: CompanyConfig) => d.key === 'assocaf')?.value ===
      'true';
    const fifth_assignment =
      res.data.find((d: CompanyConfig) => d.key === 'fifth_assignment')
        ?.value === 'true';
    const tfr =
      res.data.find((d: CompanyConfig) => d.key === 'tfr')?.value === 'true';

    setColors(primary_color);
    return {
      id,
      logo,
      assocaf,
      fifth_assignment,
      tfr,
    };
  };

  return { setCompanyConfig };
}
