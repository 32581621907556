import { Box, InputAdornment } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import IconWarningComponent from '../Icons/IconWarningComponent';
import { COLORS } from '../../utils/Colors';
import { IconsSize, IconsStyle } from '../../enums';

export type LBTTextFieldProps = TextFieldProps & {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  datatestid?: string;
};

const StyledTextField = styled(TextField)<
  TextFieldProps & {
    datatestid?: string;
    'data-track': string;
  }
>(({ datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
}));

const LBTTextField = forwardRef<HTMLInputElement, LBTTextFieldProps>(
  (
    { startIcon, endIcon, datatestid = '', ...props }: LBTTextFieldProps,
    ref,
  ) => {
    return (
      <StyledTextField
        inputRef={ref}
        fullWidth
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : undefined,
          endAdornment: endIcon ? (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ) : undefined,
        }}
        datatestid={`lbt-text-field-${datatestid}`}
        data-track={datatestid}
        {...props}
        helperText={
          props.error ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <IconWarningComponent
                size={IconsSize.MEDIUM}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().ERROR_MAIN}
              />
              {props.helperText}
            </Box>
          ) : (
            props.helperText
          )
        }
      />
    );
  },
);

export default LBTTextField;
