import { Grid, Stack, Typography } from '@mui/material';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  acceptedImageType,
  getAllTagsCallback,
  getBase64,
  getTagByIdCallback,
  postTagCallback,
  putTagCallback,
  removeNullishOrEmptyFields,
  TagClass,
  tagLoaderState,
  tagState,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { useEffect } from 'react';
import { LBTTextField } from '@laborability/components';

interface Props {
  id?: number;
}

export function TagForm({ id }: Props) {
  const postTag = useRecoilCallback(postTagCallback, []);
  const putTag = useRecoilCallback(putTagCallback, []);
  const getTag = useRecoilCallback(getTagByIdCallback, []);
  const getAllTags = useRecoilCallback(getAllTagsCallback, []);
  const [current, setCurrent] = useRecoilState(tagState);
  const tagLoader = useRecoilValue(tagLoaderState);

  const resetFormState = () => {
    setCurrent(new TagClass());
  };

  useEffect(() => {
    if (id) getTag({ id });
    else resetFormState();
  }, [id]);

  return (
    <ModalForm
      defaultValues={new TagClass()}
      isLoading={tagLoader}
      currentValues={current}
      validation={values => {
        return {
          ...(values.name === ''
            ? { name: 'Il nome del tag è obbligatorio' }
            : {}),
        };
      }}
      handleSubmit={async values => {
        if (values.id)
          return await putTag({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
          });
        return await postTag(removeNullishOrEmptyFields(values));
      }}
      resetGridState={getAllTags}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Nome"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              required
              error={!!errors?.name}
              helperText={errors?.name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              error={!!errors?.description}
              helperText={errors?.description}
            />
          </Grid>
          <Grid item mobile={12}>
            <Stack flexDirection={'row'} display={'flex'}>
              <Typography
                display={'flex'}
                flex={0.5}
                alignItems={'center'}
                justifyContent={'left'}
              >
                Immagine
              </Typography>
              <LBTTextField
                sx={{ flex: 1 }}
                type="file"
                inputProps={acceptedImageType}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const file = event.target.files && event.target.files[0];
                  if (!file) return;
                  getBase64(file).then(data => setFieldValue('image', data));
                }}
                error={!!errors?.image}
                helperText={errors?.image}
              />
            </Stack>
            <img src={values.image} style={{ maxHeight: '100px' }}></img>
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
