import { ColDef } from 'ag-grid-community';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { useGrid } from '../hooks/useGrid';
import {
  QuestionRule,
  questionRulesState,
  deleteQuestionRuleCallback,
  getAllQuestionRuleCallback,
  questionPagesState,
  questionState,
  getQuestionByIdCallback,
} from '@laborability/commons';
import { useNavigate, useParams } from 'react-router-dom';
import { QuestionRuleForm } from '../components/Forms';
import { LBTButton } from '@laborability/components';
import { useEffect } from 'react';
import { APP_ROUTES } from '../Routes';
import { appBarState } from '../recoil/states/appBar';

export function QuestionConditionPage() {
  const navigate = useNavigate();
  const { id: question_id } = useParams();
  const getAllRules = useRecoilCallback(getAllQuestionRuleCallback, []);
  const deleteRule = useRecoilCallback(deleteQuestionRuleCallback, []);
  const getQuestion = useRecoilCallback(getQuestionByIdCallback, []);
  const questionPages = useRecoilValue(questionPagesState);
  const pageQuestion = useRecoilValue(questionState);
  const setAppBarName = useSetRecoilState(appBarState);

  const { rowData, handleFetchAll, handleDelete } = useGrid<QuestionRule>({
    state: questionRulesState,
    onFetch: async () => {
      if (question_id) return await getAllRules({ id: question_id });
    },
    onDelete: async ({ id }) => {
      if (question_id) return await deleteRule({ question_id, id });
    },
  });

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Nome',
    },
    {
      field: 'execution_order',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Ordine di esecuzione',
    },
  ];

  useEffect(() => {
    if (!questionPages.length) navigate(APP_ROUTES.QUESTIONARY);
  }, [questionPages]);

  useEffect(() => {
    if (question_id) getQuestion({ id: question_id });
  }, []);

  useEffect(() => {
    setAppBarName(
      `Registra regole domande${pageQuestion?.title ? ` - ${pageQuestion.title}` : ''}`,
    );
  }, [pageQuestion]);

  return (
    <GridLayout<QuestionRule>
      pageName="Regole"
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={QuestionRuleForm}
      recordNameAccessor={item => String(item.execution_order) ?? ''}
      gridFilters={
        <LBTButton
          variant="outlined"
          color="primary"
          onClick={() => navigate(-1)}
        >
          Indietro
        </LBTButton>
      }
    />
  );
}
