import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  removeNullishOrEmptyFields,
  PageActionClass,
  getAllPageActionCallback,
  getPageActionByIdCallback,
  postPageActionCallback,
  putPageActionCallback,
  pageActionState,
  questionPagesState,
  pageActionLoaderState,
  ACTION_TYPES,
  ENTITY_TYPES,
  Modal,
  questionFlowState,
  questionPagesLoaderState,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import {
  LBTButton,
  LBTModal,
  LBTSelect,
  LBTTextField,
} from '@laborability/components';
import { useParams } from 'react-router-dom';
import { SimpleQuestionaryPageForm } from './SimpleQuestionaryPage';

const LABEL_ACTION_TYPE = {
  button: 'Bottone',
  skip: 'Skip',
  auto: 'Automatica',
};

export const LABEL_ENTITY_TYPE = {
  user: 'Utente',
  child: 'Figlio',
  partner: 'Partner',
  family: 'Famiglia',
  property: 'Proprietà',
  vehicle: 'Veicolo',
};

interface Props {
  id?: number;
  onClose?: () => void;
}

export function PageActionForm({ id, onClose }: Props) {
  const { id: page_id } = useParams();
  const postPageAction = useRecoilCallback(postPageActionCallback, []);
  const putPageAction = useRecoilCallback(putPageActionCallback, []);
  const getPageAction = useRecoilCallback(getPageActionByIdCallback, []);
  const getAllPageAction = useRecoilCallback(getAllPageActionCallback, []);
  const [current, setCurrent] = useRecoilState(pageActionState);
  const [modal, setModal] = useState<Modal | null>(null);
  const questionPages = useRecoilValue(questionPagesState);
  const questionFlow = useRecoilValue(questionFlowState);

  const pageActionLoader = useRecoilValue(pageActionLoaderState);
  const pagesLoader = useRecoilValue(questionPagesLoaderState);
  const isLoading = pageActionLoader || pagesLoader;

  const resetFormState = () => {
    setCurrent(new PageActionClass());
  };

  useEffect(() => {
    if (id) getPageAction({ id });
    else resetFormState();
  }, [id]);

  if (!page_id) return null;

  return (
    <>
      <ModalForm
        defaultValues={new PageActionClass()}
        isLoading={isLoading}
        currentValues={current}
        validation={values => {
          return {
            ...(values.name === '' ? { name: 'Il nome è obbligatorio' } : {}),
          };
        }}
        handleSubmit={async values => {
          if (values.id) {
            const res = await putPageAction({
              id: values.id,
              default_target_page_id: null,
              ...removeNullishOrEmptyFields(values),
              page_id: Number(page_id),
            });
            if (res?.data) onClose?.();
            return;
          }
          const res = await postPageAction({
            default_target_page_id: null,
            ...removeNullishOrEmptyFields(values),
            page_id: Number(page_id),
          });
          if (res?.data) onClose?.();
          return;
        }}
        resetGridState={() => getAllPageAction({ id: Number(page_id) })}
        resetFormState={resetFormState}
      >
        {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
          <>
            <Grid item mobile={12}>
              <LBTTextField
                label="Nome"
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                required
                error={!!errors?.name}
                helperText={errors?.name}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="action_type"
                name="action_type"
                value={values.action_type}
                label="Tipo"
                required
                items={ACTION_TYPES.map(type => ({
                  id: type,
                  name: LABEL_ACTION_TYPE[type],
                }))}
                handleChange={e => setFieldValue('action_type', e)}
                onBlur={handleBlur}
                error={!!errors?.action_type}
                helperText={errors?.action_type}
              />
            </Grid>
            <Grid item mobile={11}>
              <LBTSelect
                id="default_target_page_id-select"
                name="default_target_page_id"
                value={values.default_target_page_id}
                label="Pagina di destinazione default"
                handleChange={e => setFieldValue('default_target_page_id', e)}
                onBlur={handleBlur}
                fullWidth
                items={questionPages.map(item => ({
                  id: item.id as number,
                  name: item.name as string,
                }))}
                error={!!errors?.default_target_page_id}
                helperText={errors?.default_target_page_id}
              />
            </Grid>
            <Grid
              item
              mobile={1}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <LBTButton
                variant="outlined"
                onClick={() =>
                  setModal({
                    children: (
                      <SimpleQuestionaryPageForm
                        flow_id={questionFlow.id as number}
                        onClose={(id: number) => {
                          setFieldValue('default_target_page_id', id);
                          setModal(null);
                        }}
                      />
                    ),
                    title: 'Crea pagina',
                  })
                }
              >
                +
              </LBTButton>
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="action_meta.entity_type-select"
                name="action_meta.entity_type"
                value={values.action_meta?.entity_type ?? undefined}
                label="Aggiungi tipo entità"
                handleChange={e => setFieldValue('action_meta.entity_type', e)}
                onBlur={handleBlur}
                fullWidth
                items={ENTITY_TYPES.map(item => ({
                  id: item,
                  name: LABEL_ENTITY_TYPE[item],
                }))}
                error={!!(errors?.action_meta as any)?.entity_type}
                helperText={(errors?.action_meta as any)?.entity_type}
              />
            </Grid>
          </>
        )}
      </ModalForm>
      {modal && (
        <LBTModal
          open={!!modal}
          onXClose={() => setModal(null)}
          {...modal}
          modalStyle={{
            width: 'fit-content',
            maxWidth: '1000px',
            minWidth: '600px',
          }}
        />
      )}
    </>
  );
}
