import { RuleGroupType } from 'react-querybuilder';
import { Id } from './common';

export interface QuestionRule {
  id?: number;
  question_id?: number | string;
  name?: string;
  execution_order?: number;
  schema?: RuleGroupType | null;
}

export class QuestionRuleClass implements QuestionRule {
  id = 0;
  question_id = 0;
  name = '';
  execution_order = undefined;
  schema = null;
}

export interface Question {
  id?: number;
  title?: string;
  description?: string;
  hint?: string;
  question_type?: QuestionType;
  question_meta?: { [x: string]: unknown };
  attribute_meta_id?: number;
  required?: boolean;
  display_order?: number;
}

export interface PostQuestion extends Question {
  page_id: number;
}

export interface GetQuestionRule extends Id {
  question_id: number | string;
}

export class QuestionClass implements Question {
  id = 0;
  title = '';
  description = '';
  hint = '';
  question_type = 'text' as QuestionType;
  question_meta = undefined;
  attribute_meta_id = undefined;
  required = false;
  display_order = undefined;
}

export const QUESTION_TYPES = [
  'text',
  'select',
  'date',
  'boolean',
  'radio',
  'checkbox',
  'switch',
] as const;

export type QuestionType = (typeof QUESTION_TYPES)[number];

export const VALIDATION_TEXT = [
  'alphabetic',
  'numberic',
  'alphanumeric',
  'uppercase',
  'lowercase',
] as const;

export type ValidationTextType = (typeof VALIDATION_TEXT)[number];

export const VALIDATION_DATE = [
  'past',
  'past_today',
  'future',
  'future_today',
] as const;

export type ValidationDateType = (typeof VALIDATION_DATE)[number];
