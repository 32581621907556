import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  removeNullishOrEmptyFields,
  QuestionPageClass,
  questionPageState,
  getAllQuestionPageCallback,
  postQuestionPageCallback,
  putQuestionPageCallback,
  getQuestionPageByIdCallback,
  questionPageLoaderState,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { LBTLabel, LBTSpacer, LBTTextField } from '@laborability/components';
import { useParams } from 'react-router-dom';

interface Props {
  id?: number;
  onClose?: () => void;
}

export function QuestionaryPageForm({ id, onClose }: Props) {
  const { id: flow_id } = useParams();
  const postPage = useRecoilCallback(postQuestionPageCallback, []);
  const putPage = useRecoilCallback(putQuestionPageCallback, []);
  const getPage = useRecoilCallback(getQuestionPageByIdCallback, []);
  const getAllPage = useRecoilCallback(getAllQuestionPageCallback, []);
  const [current, setCurrent] = useRecoilState(questionPageState);

  const questionPageLoader = useRecoilValue(questionPageLoaderState);
  const isLoading = questionPageLoader;

  const resetFormState = () => {
    setCurrent(new QuestionPageClass());
  };

  useEffect(() => {
    if (id) getPage({ id });
    else resetFormState();
  }, [id]);

  if (!flow_id) return null;

  return (
    <ModalForm
      defaultValues={new QuestionPageClass()}
      isLoading={isLoading}
      currentValues={current}
      validation={values => {
        return {
          ...(values.name === ''
            ? { name: 'Il nome della pagina è obbligatorio' }
            : {}),
        };
      }}
      handleSubmit={async values => {
        delete values.actions;
        delete values.questions;
        if (values.id) {
          const res = await putPage({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
            flow_id: Number(flow_id),
          });
          if (res?.data) onClose?.();
          return;
        }
        const res = await postPage({
          ...removeNullishOrEmptyFields(values),
          flow_id: Number(flow_id),
        });
        if (res?.data) onClose?.();
        return;
      }}
      resetGridState={() => getAllPage({ id: Number(flow_id) })}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Titolo"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              required
              error={!!errors?.name}
              helperText={errors?.name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Sottotitolo"
              type="text"
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              error={!!errors?.description}
              helperText={errors?.description}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="hint"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.hint}
              error={!!errors?.hint}
              helperText={errors?.hint}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSpacer spacing={1} />
            <LBTLabel variant="sourceSubtitle" textAlign="left">
              Sezione media
            </LBTLabel>
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Titolo"
              type="text"
              name="media_title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.media_title}
              error={!!errors?.media_title}
              helperText={errors?.media_title}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Sottotitolo"
              type="text"
              name="media_subtitle"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.media_subtitle}
              error={!!errors?.media_subtitle}
              helperText={errors?.media_subtitle}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="media_description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.media_description}
              error={!!errors?.media_description}
              helperText={errors?.media_description}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Link"
              type="text"
              name="media_link"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.media_link}
              error={!!errors?.media_link}
              helperText={errors?.media_link}
            />
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
