import { Domain, DomainClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const domainState = atom<Domain>({
  key: getUUID(),
  default: new DomainClass(),
});

export const domainsState = atom<Domain[]>({
  key: getUUID(),
  default: [],
});

export const domainKeysState = atom<string[]>({
  key: getUUID(),
  default: [],
});

export const domainLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const domainsLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const domainKeysLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
