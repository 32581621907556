import { QuestionPage, QuestionPageClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const questionPageState = atom<QuestionPage>({
  key: getUUID(),
  default: new QuestionPageClass(),
});

export const questionPagesState = atom<QuestionPage[]>({
  key: getUUID(),
  default: [],
});

export const questionPageLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const questionPagesLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
