import { Grid } from '@mui/material';
import {
  removeNullishOrEmptyFields,
  QuestionRuleClass,
  getAllQuestionRuleCallback,
  postQuestionRuleCallback,
  putQuestionRuleCallback,
  questionRuleState,
  questionRulesState,
  questionRuleLoaderState,
  getQuestionRuleByIdCallback,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { LBTSelect, LBTTextField } from '@laborability/components';
import QueryBuilderForm from '../QueryBuilder';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  id?: number;
  onClose?: () => void;
}

export function QuestionRuleForm({ id, onClose }: Props) {
  const { id: question_id } = useParams();
  const postQuestionRule = useRecoilCallback(postQuestionRuleCallback, []);
  const putQuestionRule = useRecoilCallback(putQuestionRuleCallback, []);
  const getQuestionRule = useRecoilCallback(getQuestionRuleByIdCallback, []);
  const getAllQuestionRule = useRecoilCallback(getAllQuestionRuleCallback, []);
  const [current, setCurrent] = useRecoilState(questionRuleState);
  const allRules = useRecoilValue(questionRulesState);

  const ruleLoader = useRecoilValue(questionRuleLoaderState);
  const isLoading = ruleLoader;

  const resetFormState = () => {
    setCurrent({
      ...new QuestionRuleClass(),
      execution_order: allRules.length + 1,
      schema: { combinator: 'and', not: false, rules: [] },
    });
  };

  useEffect(() => {
    if (id && question_id) getQuestionRule({ question_id, id });
    else resetFormState();
  }, [id]);

  if (!question_id) return null;

  return (
    <ModalForm
      defaultValues={{
        ...new QuestionRuleClass(),
        execution_order: allRules.length + 1,
        schema: { combinator: 'and', not: false, rules: [] },
      }}
      isLoading={isLoading}
      currentValues={current}
      validation={values => {
        return {
          ...(values.name === '' ? { name: 'Obbligatorio' } : {}),
          ...(values.name?.length! > 255
            ? { name: 'Lunghezza massima 255 caratteri' }
            : {}),
        };
      }}
      handleSubmit={async values => {
        if (values.id) {
          const res = await putQuestionRule({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
            question_id,
          });
          if (res?.data) onClose?.();
          return;
        }
        const res = await postQuestionRule({
          ...removeNullishOrEmptyFields(values),
          question_id,
        });
        if (res?.data) onClose?.();
        return;
      }}
      resetGridState={() => getAllQuestionRule({ id: question_id })}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleBlur, setFieldValue, handleChange }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Nome"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              required
              error={!!errors?.name}
              helperText={errors?.name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="execution_order-select"
              name="execution_order"
              value={values.execution_order}
              label="Ordine di esecuzione"
              handleChange={e => setFieldValue('execution_order', e)}
              onBlur={handleBlur}
              fullWidth
              required
              items={Array.from(
                Array(allRules.length + (values.id ? 0 : 1)).keys(),
              ).map(i => ({ id: i + 1, name: i + 1 }))}
              error={!!errors?.execution_order}
              helperText={errors?.execution_order}
            />
          </Grid>
          <Grid item mobile={12}>
            <QueryBuilderForm
              query={values.schema as any}
              errors={errors}
              onQueryChange={value => setFieldValue('schema', value, false)}
            />
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
