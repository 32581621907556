import {
  authPost,
  PATH,
  authGetAll,
  authPut,
  authDelete,
  authPatch,
} from '../../http';
import {
  Id,
  Post,
  Put,
  Domain,
  GetAllDomain,
  GetAllDomainKeys,
  PatchDomain,
} from '../types';

export const postDomain = async (item: Post<Domain>) => {
  return await authPost(`api/v1/${PATH.DOMAINS}`, item);
};

export const getDomainById = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.DOMAINS}/${id}`);
};
export const getDomainsByKey = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.DOMAINS}/${PATH.PUBLIC}/${PATH.SEARCH}/${id}`,
  );
};

export const getAllDomains = async (params: GetAllDomain) => {
  return await authGetAll(
    `api/v1/${PATH.DOMAINS}/${PATH.PUBLIC}/${PATH.SEARCH}`,
    params,
  );
};

export const getAllDomainKeys = async (params: GetAllDomainKeys) => {
  return await authGetAll(
    `api/v1/${PATH.DOMAINS}/${PATH.PUBLIC}/${PATH.SEARCH}/${PATH.KEYS}`,
    params,
  );
};

export const putDomain = async (item: Put<Domain>) => {
  return await authPut(`api/v1/${PATH.DOMAINS}`, item);
};

export const patchDomain = async (item: PatchDomain) => {
  return await authPatch(`api/v1/${PATH.DOMAINS}`, item);
};

export const deleteDomain = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.DOMAINS}/${id}`);
};
