import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  getAllCompaniesCallback,
  companiesState,
  getUserByIdCallback,
  UserClass,
  getCompanyUsersCallback,
  userState,
  UserRole,
  companiesLoaderState,
  userLoaderState,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { LBTSelect, LBTTextField } from '@laborability/components';

type Props = {
  id?: number;
};

export function UserForm({ id }: Props) {
  const getUser = useRecoilCallback(getUserByIdCallback, []);
  const getAllUser = useRecoilCallback(getCompanyUsersCallback, []);
  const getAllCompanies = useRecoilCallback(getAllCompaniesCallback, []);
  const [current, setCurrent] = useRecoilState(userState);
  const companies = useRecoilValue(companiesState);

  const companiesLoader = useRecoilValue(companiesLoaderState);
  const userLoader = useRecoilValue(userLoaderState);
  const isLoading = companiesLoader || userLoader;

  const resetFormState = () => {
    setCurrent(new UserClass());
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  useEffect(() => {
    if (id) getUser({ id });
    else resetFormState();
  }, [id]);

  return (
    <ModalForm
      defaultValues={new UserClass()}
      isLoading={isLoading}
      currentValues={current}
      validation={e => {
        return {};
      }}
      handleSubmit={async () => {}}
      resetGridState={getAllUser}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              fullWidth
              label="Nome"
              type="text"
              name="first_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
              required
              error={!!errors?.first_name}
              helperText={errors?.first_name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              fullWidth
              label="Cognome"
              type="text"
              name="last_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.last_name}
              required
              error={!!errors?.last_name}
              helperText={errors?.last_name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              fullWidth
              label="Email"
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              required
              error={!!errors?.email}
              helperText={errors?.email}
            />
          </Grid>
          <Grid item mobile={6}>
            <LBTTextField
              fullWidth
              label="Password"
              type="text"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              required
              error={!!errors?.password}
              helperText={errors?.password}
            />
          </Grid>
          <Grid item mobile={6}>
            <LBTSelect
              id="role-select"
              name="role"
              value={values.role}
              label="Ruolo"
              required
              items={[
                { id: UserRole.ADMIN, name: 'ADMIN' },
                { id: UserRole.COMPANY, name: 'COMPANY' },
                { id: UserRole.USER, name: 'USER' },
              ]}
              handleChange={e => {
                setFieldValue('company_id', undefined);
                handleChange(e);
              }}
              onBlur={handleBlur}
              error={!!errors?.role}
              helperText={errors?.role}
            />
          </Grid>
          {values.role === UserRole.COMPANY && (
            <Grid item mobile={12}>
              <LBTSelect
                id="company_id-select"
                name="company_id"
                label="Seleziona azienda"
                value={values.company_id}
                required
                items={companies.map(item => ({
                  id: item.id as number,
                  name: `${item.name} - ${item.vat_number}`,
                }))}
                handleChange={handleChange}
                onBlur={handleBlur}
                error={!!errors?.company_id}
                helperText={errors?.company_id}
              />
            </Grid>
          )}
        </>
      )}
    </ModalForm>
  );
}
