export enum UserRole {
  USER = 'user',
  ADMIN = 'admin',
  COMPANY = 'company',
}

export type User = {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  role?: UserRole;
  company_id?: number;
  is_superuser?: boolean;
  is_active?: boolean;
  keycloak_id?: number | string | null;
};

export class UserClass implements User {
  id = 0;
  first_name = '';
  last_name = '';
  email = '';
  password = '';
  role = UserRole.USER;
  company_id = 0;
  is_superuser = false;
  is_active = false;
  keycloak_id = null;
}
