import { Grid } from '@mui/material';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  removeNullishOrEmptyFields,
  QuestionPageClass,
  getAllQuestionPageCallback,
  postQuestionPageCallback,
  questionPageLoaderState,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { LBTTextField } from '@laborability/components';

interface Props {
  flow_id: number;
  onClose: (id: number) => void;
}

export function SimpleQuestionaryPageForm({ flow_id, onClose }: Props) {
  const postPage = useRecoilCallback(postQuestionPageCallback, []);
  const getAllPage = useRecoilCallback(getAllQuestionPageCallback, []);

  const questionPageLoader = useRecoilValue(questionPageLoaderState);
  const isLoading = questionPageLoader;

  if (!flow_id) return null;

  return (
    <ModalForm
      defaultValues={new QuestionPageClass()}
      isLoading={isLoading}
      currentValues={new QuestionPageClass()}
      validation={values => {
        return {
          ...(values.name === ''
            ? { name: 'Il nome della pagina è obbligatorio' }
            : {}),
        };
      }}
      handleSubmit={async values => {
        const res = await postPage({
          ...removeNullishOrEmptyFields(values),
          flow_id: Number(flow_id),
        });
        if (res?.data) onClose?.(res.data.id);
        return;
      }}
      resetGridState={() => getAllPage({ id: Number(flow_id) })}
      resetFormState={() => {}}
    >
      {({ values, errors, handleChange, handleBlur }) => (
        <Grid item mobile={12}>
          <LBTTextField
            label="Titolo"
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            required
            error={!!errors?.name}
            helperText={errors?.name}
          />
        </Grid>
      )}
    </ModalForm>
  );
}
