import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  postDomainCallback,
  domainState,
  domainLoaderState,
  DomainClass,
  Domain,
  getDomainByIdCallback,
  getDomainsByKeyCallback,
  putDomainCallback,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { LBTTextField } from '@laborability/components';

interface Props {
  id?: number;
  onClose?: () => void;
  extraParams: { parent_id?: string; domain_id?: string };
}

export function DomainValueForm({ id, onClose, extraParams }: Props) {
  const postDomain = useRecoilCallback(postDomainCallback, []);
  const putDomain = useRecoilCallback(putDomainCallback, []);
  const getDomain = useRecoilCallback(getDomainByIdCallback, []);
  const getAllDomain = useRecoilCallback(getDomainsByKeyCallback, []);
  const [current, setCurrent] = useRecoilState(domainState);
  const { parent_id, domain_id } = extraParams;

  const domainLoader = useRecoilValue(domainLoaderState);
  const isLoading = domainLoader;
  const defaultValues = {
    ...new DomainClass(),
    domain: domain_id ?? '',
    parent_id: parent_id ? Number(parent_id) : undefined,
  };

  const resetFormState = () => {
    setCurrent(defaultValues);
  };

  useEffect(() => {
    if (id) getDomain({ id });
    else resetFormState();
  }, [id]);

  if (!domain_id) return null;

  return (
    <ModalForm
      defaultValues={defaultValues}
      isLoading={isLoading}
      currentValues={current}
      validation={values => {
        return {
          ...(values.domain === ''
            ? { domain: 'Il nome del dominio è obbligatorio' }
            : {}),
          ...(values.value === '' ? { value: 'Il valore è necessario' } : {}),
        };
      }}
      handleSubmit={async (values: Domain) => {
        if (values.id) {
          const res = await putDomain({
            ...values,
            id: values.id,
          });
          if (res?.data) onClose?.();
          return;
        }
        const res = await postDomain(values);
        if (res?.data) onClose?.();
      }}
      resetGridState={() => getAllDomain({ id: domain_id })}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleChange, handleBlur }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Dominio"
              type="text"
              name="domain"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.domain}
              required
              disabled
              error={!!errors?.domain}
              helperText={errors?.domain}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Valore"
              type="text"
              name="value"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.value}
              required
              error={!!errors?.value}
              helperText={errors?.value}
            />
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
