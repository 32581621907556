export interface Domain {
  id?: number;
  parent_id?: number;
  domain: string;
  value: string;
}

export class DomainClass implements Domain {
  id = 0;
  parent_id = undefined;
  value = '';
  domain = '';
}

export interface GetAllDomain {
  parent_id?: number;
}

export interface GetAllDomainKeys {
  domain_id?: string;
}

export interface PatchDomain {
  domain_key: string;
  new_domain_key: string;
}
