import { Box, Modal, ModalProps, Stack } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from '../../utils/Colors';
import IconCrossComponent from '../Icons/IconCrossComponent';
import { IconsSize, IconsStyle } from '../../enums';
import LBTLabel from '../Label';
import { useBreakpoint } from '@laborability/commons';
import { CSSProperties } from 'react';

export type LBTModalProps = ModalProps & {
  title: string;
  subtitle1?: string;
  open: boolean;
  datatestid?: string;
  onXClose?: () => void;
  modalStyle?: CSSProperties;
};

const StyledBox = styled(Box)`
  cursor: pointer;
  padding: 10px;
`;

export default function LBTModal({
  children,
  open = false,
  title,
  subtitle1,
  datatestid = 'lbt-modal',
  modalStyle,
  ...props
}: LBTModalProps) {
  const { isDesktop } = useBreakpoint();

  return (
    <Modal open={open} {...props}>
      <Stack
        style={{
          position: 'absolute',
          top: isDesktop ? '50%' : 0,
          left: isDesktop ? '50%' : 0,
          transform: isDesktop ? 'translate(-50%, -50%)' : undefined,
          width: isDesktop ? '900px' : '100%',
          height: isDesktop ? undefined : '100%',
          ...(isDesktop ? { maxWidth: '900px' } : { maxWidth: '100%' }),
          border: isDesktop ? '1px solid #000' : undefined,
          backgroundColor: COLORS.getInstance().WHITE,
          ...modalStyle,
        }}
      >
        <Stack
          style={{
            height: '100%',
          }}
        >
          <Stack
            sx={{
              borderBottom: '1px solid #DDDDDD',
              padding: '24px 0',
              margin: isDesktop ? '0 22px' : '0 16px',
            }}
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <StyledBox width={IconsSize.MEDIUM} />
              <LBTLabel
                variant="miscTitleSource"
                padding="7px"
                textAlign="center"
                component="h3"
              >
                {title}
              </LBTLabel>
              <StyledBox
                onClick={() =>
                  'onXClose' in props &&
                  typeof props.onXClose === 'function' &&
                  props.onXClose()
                }
              >
                <IconCrossComponent
                  color="#000"
                  size={IconsSize.MEDIUM}
                  style={IconsStyle.FILLED}
                />
              </StyledBox>
            </Stack>
            {subtitle1 && (
              <LBTLabel variant="miscDescription">{subtitle1}</LBTLabel>
            )}
          </Stack>

          <Box
            sx={{
              padding: isDesktop ? '24px 22px' : '24px 16px',
              overflow: 'auto',
            }}
          >
            {children}
          </Box>
        </Stack>
      </Stack>
    </Modal>
  );
}
